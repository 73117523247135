<template>
  <div class="avatar" :class="{ fallback: isFallback }"></div>
</template>

<script lang="ts" setup>
import { useUserStateStore } from "@/stores/UserStateStore";
import { storeToRefs } from "pinia";

type Avatar = {
  size?: string;
  url: string;
};

const props = defineProps<{ avatarSize?: "default" | "large" }>();

const userSessionStateStore = useUserStateStore();
const { userState } = storeToRefs(userSessionStateStore);

const userAvatar: Ref<Partial<Avatar> | null> = ref({
  size: "4rem",
  url: userState.value?.avatar ? `url(${userState.value.avatar})` : "",
});
const isFallback = ref(false);

if (userAvatar.value && props.avatarSize === "large") {
  userAvatar.value.size = "8rem";
}

userSessionStateStore.$subscribe((_mutation, state) => {
  if (state.userState?.avatar) {
    userAvatar.value = {
      ...userAvatar.value,
      url: `url(${state.userState.avatar})`,
    };
  } else {
    userAvatar.value = {
      ...userAvatar.value,
      url: `url(${AVATAR_FALLBACK_URL})`,
    };
  }
});
</script>

<style scoped>
.avatar {
  background: transparent v-bind("userAvatar?.url") center center no-repeat;
  background-size: cover;
  border: 4px solid var(--color-neutral-60);
  border-radius: 50%;
  display: block;
  height: v-bind("userAvatar?.size");
  width: v-bind("userAvatar?.size");
}

.fallback {
  border: 0;
}
</style>
